import gql from "graphql-tag";

//Logout
export const LOGOUT_MUTATION = gql`
  mutation Logoutuser {
    logoutuser {
      userId
      token
    }
  }
`;

export const ADD_USER_MUTATION = gql`
  mutation CreateUser($createAcc: createuser) {
    createUser(createAcc: $createAcc) {
      user_id
      first_name
      last_name
    }
  }
`;
export const CURRENT_USER_MUTATION = gql`
  query CurrentUser {
    currentUser {
      user_id
      first_name
      last_name
      username
      email
      phone_number
      role {
        group_id {
          group_name
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const EDIT_USER_MUTATION = gql`
  mutation UpdateFileCategory(
    $updateFileCategoryId: Int!
    $input: FileCategoryInput
  ) {
    updateFileCategory(id: $updateFileCategoryId, input: $input) {
      editable
      file_category_id
      file_category_name
    }
  }
`;
export const LOGIN_USER_MUTATION = gql`
  mutation LoginEmployee($username: String!, $password: String!) {
    loginEmployee(username: $username, password: $password) {
      authData {
        token
      }
      employee {
        id
        first_name
        last_name
        phone_number
        title
        address
        initials
      }
      user {
        role {
          id
          group_id {
            group_id
            group_name
          }
        }
      }
    }
  }
`;
export const DELETE_USER_MUTATION = gql`
  mutation DeleteFileCategory($deleteFileCategoryId: Int!) {
    deleteFileCategory(id: $deleteFileCategoryId)
  }
`;
//SRT RAW

export const UPDATE_USER = gql`
  mutation Updateuser($updateuserId: Int!, $input: UserInput) {
    updateuser(id: $updateuserId, input: $input) {
      user_id
      first_name
      last_name
      username
      email
      date_of_birth
      gender
      location
      profile_picture
      phone_number
    }
  }
`;

export const REQUEST_RESETPASSWORD_MUTATION = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      userId
      username
      email
    }
  }
`;

export const RESETPASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $code: String!
    $userId: String!
    $confirmPassword: String!
    $password: String!
  ) {
    resetPassword(
      code: $code
      userId: $userId
      confirmPassword: $confirmPassword
      password: $password
    ) {
      email
      userId
      username
    }
  }
`;
//GetWeeklyReport
export const GET_WEEKLY_REPORT = gql`
  query GetWeeklyReports(
    $offset: Int!
    $limit: Int!
    $filter: WeeklyReportFilter
  ) {
    getWeeklyReports(offset: $offset, limit: $limit, filter: $filter) {
      count
      data {
        weekly_report_id
        report_type {
          report_type
        }
        file_name
        week {
          week_name
        }
        end_week_date
        records
        amount
        uploader
        uploaded_date
        client_id
        createdAt
        updatedAt
      }
    }
  }
`;
//GetWeek
export const GET_WEEK = gql`
  query GetWeeklyReports {
    getWeeks {
      week_id
      week_name
      createdAt
      updatedAt
    }
  }
`;
//GetReportType
export const GET_REPORT_TYPE = gql`
  query GetWeeklyReports {
    getReportTypes {
      report_type_id
      report_type
      createdAt
      updatedAt
    }
  }
`;
//Transponder
//update
export const UPDATE_TRANSPONDER = gql`
  mutation UpdateRequest(
    $updateRequestId: ID!
    $input: UpdateVehicleAssetRequest!
  ) {
    updateRequest(id: $updateRequestId, input: $input) {
      id
    }
  }
`;
//Shipped transponder
//get
export const GET_SHIPPED_TRANSPONDER = gql`
  query GetShippedTransponders(
    $offset: Int!
    $limit: Int!
    $searchParams: String
  ) {
    getShippedTransponders(
      offset: $offset
      limit: $limit
      searchParams: $searchParams
    ) {
      count
      data {
        id
        transponder
        transponder_id
        transponder2
        transponder_id2
        transponder_status
        equipment_id
        client
        shipped_date
        deactivated_date
        createdAt
        updatedAt
      }
    }
  }
`;
//update
export const UPDATE_SHIPPED_TRANSPONDER = gql`
  mutation UpdateShippedTransponder(
    $updateShippedTransponderId: ID!
    $input: EditTransponder!
  ) {
    updateShippedTransponder(id: $updateShippedTransponderId, input: $input) {
      id
    }
  }
`;
//graph
export const GetAmazonTopVehicles = gql`
  query GetAmazonTopVehicles($year: Int, $weekId: ID) {
    getAmazonTopVehicles(year: $year, week_id: $weekId) {
      afpTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
      powerUnitTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
      trailersTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
      tomTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
      dspMMbtTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
    }
  }
`;
export const YtdTollSummary = gql`
  query YtdTollSummary($year: Int, $weekId: ID) {
    getAmazonAccountSummary(year: $year, week_id: $weekId) {
      ytdTollSummary {
        ytdTollSpend
        ytdTrailersSpend
        ytdAfpSpend
        ytdTomSpend
        ytdDspMMbtSpend
        ytdOwnedAssetCount
        ytdRentalAssetCount
        ytdCitationsCount
        impoundCount
      }
      agencyData {
        label
        value
      }
      tollSpendByMonth {
        label
        value {
          report_type
          amount
        }
      }
      weeklyTollSpendChart {
        label
        value {
          report_type
          amount
        }
      }
    }
  }
`;
//AAP Report
//get
export const GET_AAP_REPORT = gql`
  query GetAAPReport(
    $offset: Int!
    $limit: Int!
    $filter: AAPFilters
    $searchParams: String
  ) {
    getAAPReport(
      offset: $offset
      limit: $limit
      filter: $filter
      searchParams: $searchParams
    ) {
      count
      data {
        id
        client
        license_plate
        license_plate_state
        equipment_id
        transponder
        transponder_id
        vin
        agency_account
        agency_account_id
        asset_type
        asset_update
        assignment_date
        body_type
        dot_ndot
        fleet_name
        license_plate_country
        lifecycle_state
        lifecycle_state_reason
        operator
        order
        owner
        previous_assignment
        program {
          id
          name
        }
        original_program
        purchase_type
        scac_company_contact
        secondary_transponder
        secondary_transponder_id
        status_date_notes
        transponder_agency
        branding
        source
        vehicle_status
        week
        file_name
        createdAt
        updatedAt
        start_date
        end_date
      }
    }
  }
`;
//Agencie
//get
export const GET_AGENCIES = gql`
  query GetAgencies {
    getAgencies {
      agency_id
      agency_name
      abbr
      agency_state
      createdAt
      updatedAt
    }
  }
`;
//post
export const ADD_AGENCY = gql`
  mutation CreateAmazonAgencies(
    $agencyName: String!
    $abbr: String!
    $agencyState: String!
  ) {
    createAmazonAgencies(
      agency_name: $agencyName
      abbr: $abbr
      agency_state: $agencyState
    ) {
      agency_id
    }
  }
`;
//state
//get
export const GET_STATES = gql`
  query GetStates {
    getStates {
      state_id
      state_name
      state_code
      createdAt
      updatedAt
    }
  }
`;
//update
export const UPDATE_AAP_REPORT = gql`
  mutation UpdateVehicleAsset(
    $input: UpdateVehicleAsset!
    $updateVehicleAssetId: ID!
  ) {
    updateVehicleAsset(input: $input, id: $updateVehicleAssetId) {
      id
    }
  }
`;

export const AMAZON_QUERY = gql`
  query GetAmazonTransactions(
    $offset: Int!
    $limit: Int!
    $searchParams: String
    $filter: AmazonTransactionFilter
  ) {
    getAmazonTransactions(
      offset: $offset
      limit: $limit
      searchParams: $searchParams
      filter: $filter
    ) {
      count
      data {
        invoice_id
        license_plate
        transponder
        state_code
        agency_name
        exit_date_time
        exit_name
        class
        toll
        higher_rate
        dispute
        unit
        equipment_id
        owner
        asset_type
        reason
        source
        transaction_type
        createdAt
        updatedAt
      }
    }
  }
`;

export const WEEK_QUERY = gql`
  query GetWeeks {
    getWeeks {
      week_id
      week_name
      createdAt
      updatedAt
    }
  }
`;

export const PROGRAM_QUERY = gql`
  query GetReportTypes {
    getReportTypes {
      report_type_id
      report_type
      createdAt
      updatedAt
    }
  }
`;

export const GET_TRANSPONDER_STATUS = gql`
  query GetUpdateRequests(
    $offset: Int!
    $limit: Int!
    $searchParams: String
    $filter: VehicleUpdateRequestFilter
  ) {
    getUpdateRequests(
      offset: $offset
      limit: $limit
      searchParams: $searchParams
      filter: $filter
    ) {
      count
      data {
        id
        asset {
          id
          license_plate
          transponder
        }
        equipment_id
        license_plate
        transponder
        transponder_id
        program {
          description
          id
          name
        }
        comment
        email
        shipping_address
        request_type
        approved
        status
        courier
        tracking_number
        tracking_url
        shipped_at
        delivered_at
        approved_at
        rejected_at
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_STATUS_MUTATION = gql`
  mutation UpdateRequest(
    $updateRequestId: ID!
    $input: UpdateVehicleAssetRequest!
  ) {
    updateRequest(id: $updateRequestId, input: $input) {
      id
    }
  }
`;

export const TOP_VEHICLE_QUERY = gql`
  query GetAmazonTopVehicles($year: Int, $weekId: ID) {
    getAmazonTopVehicles(year: $year, week_id: $weekId) {
      afpTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
      powerUnitTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
      trailersTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
      tomTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
      dspMMbtTopVehicles {
        toll
        asset_type
        license_plate
        transponder
        state_code
        owner
        equipment_id
      }
    }
  }
`;

export const ROLLBACK_MUTATION = gql`
  mutation RollBackAmazonTransaction($rollBackAmazonTransactionId: ID!) {
    rollBackAmazonTransaction(id: $rollBackAmazonTransactionId) {
      weekly_report_id
    }
  }
`;

// add transponder request
export const ADD_TRANSPONDER_REQUEST = gql`
  mutation ReplaceVehicleAsset($input: ReplaceVehicleAsset!) {
    replaceVehicleAsset(input: $input) {
      id
    }
  }
`;
